import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Play romanian whist online" />
      <main>
        <div className="o-container c-hero u-pv-20">
          <div className="c-hero__main">
            <h1 className="u-mb">
              Play romanian whist online with your friends!
            </h1>
            <p>Realtime, free, 3 to 6 players online card game. </p>
            <p className="u-mb-20">
              <Link to="/how-to-play" className="c-link">
                See game rules →
              </Link>
            </p>
            <Link to="/app" className="c-button c-button--red">
              Let's play!
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
